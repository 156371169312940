<template>
  <div>
    <div v-if="tenant.id === 'truenorth'">
      <h1>We'd love to hear from you!</h1>
      <p>
        Please fill in the form on the next page in case you have questions or are looking for support.
      </p>
      <p>
        Please provide at much details as possible so we can help you faster. Make sure to include the challenge you participate in and your username so we can quickly find your information.
      </p>
      <v-btn x-large color="primary" href="https://truenorthchallenges.com/pages/contact-us" target="_blank">Contact Us</v-btn>
    </div>
    <div v-else>
      <h1>We'd love to hear from you!</h1>
      <p>
        Our goal is to encourage everyone to live an active and healthy lifestyle. 
        Studies show having healthy employees improves productivity and radically reduces healthcare costs.
      </p>
      <p>
        <v-btn outlined color="primary" to="/about">More about us</v-btn>
      </p>

      <v-row class="mb-8 mx-0 align-center">
        <v-col cols="12" class="mb-0 pb-0">
          <h3 class="mb-0 pb-0">How can we best help you?</h3>
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='contact'?'primary lighten-2':''" 
            icon="fal fa-info-circle"
            icon-color="black"
            title="Contact" 
            text="For general inquiries and information on our platform." 
            @click="mode='contact'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='demo'?'primary lighten-2':''" 
            icon="fal fa-user-headset"
            icon-color="black"
            title="Demo or Call" 
            text="Schedule a free 15-30 minute demo or call." 
            @click="mode='demo'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="mode=='support'?'primary lighten-2':''" 
            icon="fal fa-life-ring"
            icon-color="black"
            title="Support" 
            text="For users participating in an event on our platform." 
            @click="mode='support'"/>
        </v-col>
      </v-row>

    <div v-if="mode=='contact'">
      <h3>Contact Us</h3>
      <p>Leave a message using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

      <v-form lazy-validation ref="form" :action="tenant.contactFormPostUrl" method="POST" @submit="submit('contact')">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="phone"
        label="Phone number (optional)"
        name="phone"
        type="tel"
      ></v-text-field>

        <v-textarea v-model="comments" required label="Your message:" name="comments"></v-textarea>
        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Contact Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>
      
        <v-btn class="primary" type="submit" x-large>Submit</v-btn>
      </v-form> 
    </div>

    <div v-if="mode=='demo'">
      <h3>Request a demo or call</h3>
      <p>Leave a message using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

      <v-form lazy-validation ref="form" :action="tenant.contactFormPostUrl" method="POST" @submit="submit('demo')">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="phone"
        label="Phone number (optional)"
        name="phone"
        type="tel"
      ></v-text-field>

      <v-text-field label="Date and time preference" name="when" hint="When would you prefer to have this demo? Please include your time zone." />

      <v-textarea v-model="comments" class="mb-8" label="Specific topics, features or comments:" persistent-hint hint="Let us know if you have specific questions or features you'd like to include in the demo." name="comments"></v-textarea>

        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Demo/Call Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>
      
        <v-btn class="primary" type="submit" x-large>Submit</v-btn>
      </v-form> 
    </div>
    <div v-if="mode=='support'">
      <h3>Support Question</h3>
      <p><strong>Please email directly to the organization hosting your event for faster response times!</strong></p>
      <p>Alternatively, leave a message using form below and we'll get back to you. Please make sure to enter all fields so we can help you more efficiently.</p>

      <v-form lazy-validation ref="form" :action="tenant.supportFormPostUrl" method="POST">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="email"
          type="email"
          required
        ></v-text-field>

      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization, event, or challenge name"
        name="organization"
        required
      ></v-text-field>

      <v-text-field
        v-model="profileId"
        label="Your profile ID"
        name="profileID"
        hint="Please paste your profile ID. You can find this on the Edit Profile page."
        persistent-hint
      ></v-text-field>

        <v-textarea v-model="comments" required label="Your question:" name="comments" persistent-hint hint="Please include as many details as possible."></v-textarea>
        
        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
        <input type="hidden" name="mode" value="Support Form"/>
        <input type="hidden" name="tenant" :value="tenant.name"/>

        <v-btn class="primary" type="submit" x-large>Submit</v-btn>
      </v-form> 
    </div>

    <div v-if="tenant.id === 'cofi'">
      <h3 class="mt-8">Trusted by organizations world-wide</h3>
      <v-slide-group show-arrows v-model="currentSlideIndex">
        <v-slide-item
          v-for="(item, idx) in siteData.customers.filter(x => (!showCorporateCustomerLogos && x.showcase) || (showCorporateCustomerLogos && x.corporate))"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <a :href="item.url" target="_blank">
          <v-sheet :elevation="1" @click="toggle" :input-value="active" class="text-center pa-2 ma-2 d-flex" height="60" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
            <img :src="`https://sodisp.imgix.net/web/customers/${item.id}.png?h=50&w=100`" :alt="item.name" class="d-inline-block" :style="`align-self: center; max-width:100px; max-height:50px;`"/>
          </v-sheet>
          </a>
        </v-slide-item>
      </v-slide-group>      
      </div>
    </div>
  </div>
</template>


<script>
import siteData from '@/data/site.json'
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import { EventBus } from '@/plugins/eventbus.js';
import BigButton from "@/components/BigButton";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    BigButton,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      siteData: siteData,
      showCorporateCustomerLogos: tenant.id == 'cofi',
      currentSlideIndex: 0,
      interval: 0,
      valid: true,
      mode: null,//'contact',
      timeZoneOlson: null,
      org: '',
      phone: '',
      profileId: '',
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: ''
    };
  },
  created() {
    var maxIndex = siteData.customers.filter(x => x.corporate).length;
    this.interval = setInterval(() => this.currentSlideIndex > maxIndex ? this.currentSlideIndex = 0 : this.currentSlideIndex++, 1000);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  async mounted() {
    //this.track();
    this.name = this.$route.query.name;
    this.email = this.$route.query.email;
    this.comments = this.$route.query.comments;
    this.timeZoneOlson = DateTime.now().zoneName;

    if (this.email) {
      console.log('Scrolling to form');
      await this.$nextTick();
      this.$refs.form.$el.scrollIntoView();
    }
    console.log('this.user', this.user);
    if (this.user) {
      this.loadProfile();
    }
    EventBus.$on('login-state-change', async user => {
      if (user) {
        this.loadProfile();
      }
    });

    console.log('// analytics', this.$analytics.synced);
  },
  methods: {
    submit(formName) {
      this.$analytics.event('contact_submit', { 'event_category': 'contact', 'event_label': formName });
      this.$analytics.event('generate_lead', {
        currency: 'USD',
        value: 0, // unknown value
      });
      console.log('Event logged.');
    },
    async loadProfile() {
      var response = await profileService.get();
      this.profileId = response.data.id;
      this.name = response.data.name;
      this.email = response.data.email;
    },
    track () {

      //console.log('Tracking event', this.$analytics, 'includes', window.enableAnalyticsProperty);
      //window.disableAnalyticsProperty('UA-163164505-2');
      //window.enableAnalyticsProperty('UA-163164505-2');
      //this.$analytics.query('config', 'UA-163164505-2', {'send_page_view': false});
      //this.$analytics.enableCustomTrackers(this, [ 'UA-163164505-2' ]);
      //this.$analytics.disableCustomTrackers(this);
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
</style>